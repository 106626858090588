var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container-fluid"},[_c('vueper-slides',{staticClass:"no-shadow",attrs:{"visible-slides":7,"fixed-height":"100px","slide-multiple":"","bullets":false,"gap":2,"slide-ratio":1 / 18,"dragging-distance":200,"breakpoints":{
      800: { visibleSlides: 2, slideMultiple: 2, slideRatio: 1 / 6, gap: 2 },
      1000: { visibleSlides: 3, slideMultiple: 3, slideRatio: 1 / 8, gap: 2 },
      1200: { visibleSlides: 4, slideMultiple: 4, slideRatio: 1 / 8, gap: 2 },
      1400: {
        visibleSlides: 5,
        slideMultiple: 5,
        slideRatio: 1 / 10,
        gap: 2,
      },
      1700: {
        visibleSlides: 6,
        slideMultiple: 6,
        slideRatio: 1 / 12,
        gap: 2,
      },
    }}},_vm._l((_vm.cardStatus),function(status,i){return _c('vueper-slide',{key:i,attrs:{"title":status.title},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('div',{staticClass:"card text-center",staticStyle:{"cursor":"pointer","height":"94px","background":"var(--card-bg)","box-shadow":"0 1px 2px rgba(0, 0, 0, 0.12)","border-radius":"8px","border-color":"var(--bordercolorform)"}},[_c('div',{staticClass:"mt-5",on:{"click":function($event){return _vm.detailCard(status.type)}}},[_c('p',{staticClass:"card-status-title",staticStyle:{"color":"var(--textlabelkuning)"}},[_vm._v(" "+_vm._s(status.title)+" ")]),_c('p',{staticClass:"card-status-text",staticStyle:{"color":"#74f972"}},[_vm._v(" "+_vm._s(status.value)+" ")])]),_c('b-modal',{attrs:{"size":"lg","title":"Detail Status","centered":""},on:{"hidden":_vm.onHidden},model:{value:(_vm.detail),callback:function ($$v) {_vm.detail=$$v},expression:"detail"}},[_c('div',{staticStyle:{"margin":"2%"}},[_c('DxDataGrid',{ref:_vm.my - _vm.data - _vm.grid,refInFor:true,attrs:{"data-source":_vm.itemsdetail,"show-borders":true,"word-wrap-enabled":false,"column-auto-width":true,"remote-operations":true,"column-hiding-enabled":false}},[_c('DxSearchPanel',{attrs:{"visible":true}}),_c('DxFilterRow',{attrs:{"visible":false}}),_c('DxExport',{attrs:{"enabled":true}}),_c('DxColumn',{attrs:{"data-field":"serial","caption":"Serial","width":100}}),_c('DxColumn',{attrs:{"data-field":"name","caption":"Name","width":150}}),_c('DxColumn',{attrs:{"data-field":"battery_level","caption":"Battery","width":150}}),_c('DxColumn',{attrs:{"data-field":"location","caption":"Location","width":170}}),_c('DxPaging',{attrs:{"page-size":10}})],1)],1)])],1)]},proxy:true}],null,true)})}),1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }