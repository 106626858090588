<template>
  <div class="card card-custom mb-3">
    <div class="card-header">
      <div class="card-title">
        <h3 class="card-label cardLabelTiket">Level of priority</h3>
      </div>

      <div class="card-toolbar">
        <div
          class="dropdown dropdown-inline"
          data-toggle="tooltip"
          title=""
          data-placement="left"
          data-original-title="Quick actions"
        >
          <a
            href="#"
            @click="detail = !detail"
            class="btn btn-hover-light-primary btn-sm btn-icon"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <i class="ki ki-bold-more-hor"></i>
          </a>
        </div>
      </div>
    </div>

    <div
      class="row no-gutters"
      style="display: flex; justify-content: center !important"
    >
      <div style="width: 100%;min-height: 300px">
        <DoughnutExample
          :width="250"
          :height="250"
          ref="skills_chart"
          :chart-data="chartData"
          :options="options2"
        >
        </DoughnutExample>
      </div>
    </div>

    <b-modal
      v-model="detail"
      size="lg"
      title="Detail Priority"
      centered
      hide-footer
    >
      <div>
        <DxDataGrid
          :ref="my - data - grid"
          :data-source="detailPrior"
          :show-borders="true"
          :word-wrap-enabled="false"
          :column-auto-width="false"
          :remote-operations="true"
          :column-hiding-enabled="true"
        >
          <DxSearchPanel :visible="true" />
          <DxFilterRow :visible="false" />
          <DxExport :enabled="true" />
          <DxColumn data-field="site_id" caption="Site ID" />
          <DxColumn data-field="box_sn" caption="Box SN" />
          <DxColumn data-field="customer.name" caption="Customer" />
          <DxColumn data-field="location_name" caption="Site Location" />
          <DxColumn data-field="address" caption="Address" />
          <DxPaging :page-size="10" />
        </DxDataGrid>
      </div>
      <!-- <pre>{{ infoModal.content }}</pre> -->
    </b-modal>
  </div>
</template>

<script>
import DoughnutExample from "../dashboard/chart/demo";
import WebService from "../../core/services/api";
import {
  DxDataGrid,
  DxSearchPanel,
  DxFilterRow,
  DxColumn,
  DxExport,
  DxPaging
} from "devextreme-vue/data-grid";

const options2 = {
  legend: {
    display: true
  },
  responsive: true,
  maintainAspectRatio: false,
  animation: {
    animateRotate: false
  }
};
export default {
  components: {
    DxDataGrid,
    DxSearchPanel,
    DxFilterRow,
    DxColumn,
    DxExport,
    DxPaging,
    DoughnutExample
  },
  data() {
    return {
      detail: false,
      options2,
      chartData: {
        labels: [],
        datasets: [
          {
            backgroundColor: ["#F97372", "#72F9F1"],
            data: []
          }
        ]
      },
      detailPrior: [],
      chartOptions: {
        colors: ["#F97372", "#72F9F1"],
        stroke: {
          width: 0
        },
        legend: {
          fontSize: "14px",
          fontFamily: "Helvetica, Arial",
          labels: {
            colors: "var(--textlabelkuning)",
            useSeriesColors: false
          },
          fontWeight: 400,
          itemMargin: {
            vertical: 10
          }
          // formatter: function(seriesName) {
          //   return '<label style="color: var(--textlabelkuning)">' + '<span>' + seriesName + '</span>' + '</label>'
          // }
        },
        dataLabels: {
          enabled: false
        },
        labels: [],
        responsive: [
          {
            breakpoint: 700,
            options: {
              chart: {
                height: 300,
                width: 350
              },
              legend: {
                position: "bottom",
                fontSize: "11px"
              }
            }
          },
          {
            breakpoint: 768,
            options: {
              chart: {
                height: 300,
                width: 380
              },
              legend: {
                position: "bottom",
                fontSize: "11px"
              }
            }
          },
          {
            breakpoint: 1500,
            options: {
              chart: {
                height: 300,
                width: 400
              },
              legend: {
                position: "bottom",
                fontSize: "11px"
              }
            }
          }
        ],
        plotOptions: {
          pie: {
            donut: {
              labels: {
                show: true,
                name: {
                  show: true,
                  fontSize: "22px",
                  fontFamily: "Rubik",
                  color: "var(--textlabelkuning)",
                  offsetY: -10
                },
                value: {
                  show: true,
                  fontSize: "16px",
                  fontFamily: "Helvetica, Arial, sans-serif",
                  color: "var(--textlabelkuning)",
                  offsetY: 16,
                  formatter: function(val) {
                    return val;
                  }
                },
                total: {
                  show: true,
                  fontSize: "12px",
                  label: "Total",
                  color: "var(--textlabelkuning)",
                  formatter: function(w) {
                    return w.globals.seriesTotals.reduce((a, b) => {
                      return a + b;
                    }, 0);
                  }
                }
              }
            }
          }
        },
        series: []
      }
    };
  },

  mounted() {
    this.getDataUser();
  },
  methods: {
    getDataUser() {
      WebService.getProfilUser().then(
        res => {
          this.profileData = res.data;
          if (res.data.role_id !== 31) {
            this.getChart();
            this.getDetail();
          } else {
            const custid = res.data.customer_id;
            this.getChartByowner(custid);
            this.getDetail();
          }
        },
        error => {
          console.log(error);
          this.profileData = {};
        }
      );
    },
    getDetail() {
      const customer = this.profileData.customer_id;
      if (this.profileData.role_id !== 31) {
        let databody = JSON.stringify([{ selector: "created_at", desc: true }]);
        WebService.getlocalsiteAll(encodeURI(databody)).then(
          res => {
            this.detailPrior = res.data.data;
          },
          error => {
            console.log(error);
            this.detailPrior = [];
          }
        );
      } else {
        WebService.getlocalsiteById(customer).then(
          res => {
            this.detailPrior = res.data.data;
          },
          error => {
            console.log(error);
            this.detailPrior = [];
          }
        );
      }

      this.loading = false;
    },
    getChart() {
      WebService.getChartPrior().then(
        res => {
          const arrVal = [];
          res.data.data.forEach(item => {
            this.chartOptions.labels.push(item.group_name);
            this.chartData.labels.push(item.group_name);
            arrVal.push(Number(item.value));
          });
          this.chartOptions.series = arrVal;
          this.chartData.datasets[0].data = arrVal;
          this.$refs.skills_chart.update();
        },
        error => {
          console.log(error);
        }
      );
      this.loading = false;
    },
    getChartByowner(id) {
      WebService.getChartPriorBycustomerId(id).then(
        res => {
          const arrVal = [];
          res.data.data.forEach(item => {
            this.chartOptions.labels.push(item.group_name);
            this.chartData.labels.push(item.group_name);
            arrVal.push(Number(item.value));
          });
          this.chartOptions.series = arrVal;
          this.chartData.datasets[0].data = arrVal;
          this.$refs.skills_chart.update();
        },
        error => {
          console.log(error);
        }
      );
      this.loading = false;
    }
  }
};
</script>

<style>
#app {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(430px, 1fr));
  grid-gap: 4em;
  padding: 2em;
  place-items: center center;
}
</style>
