<template>
  <div class="container-fluid ml-6">
    <div v-if="false">
      <Search />
    </div>
    <div class="mb-6">
      <cardstatus />
    </div>
    <div class="row">
      <div v-for="item in items" :key="item.id" class="col-xl-4 col-lg-6 col-md-6">
        <div v-if="item.id === 1">
          <Sla />
        </div>
        <div v-else-if="item.id === 2">
          <PiechartsPrior />
        </div>
        <div v-else-if="item.id === 5">
          <Piecharts />
        </div>
        <div v-else-if="item.id === 6">
          <Barcharts />
        </div>
        <div v-else-if="item.id === 7">
          <Piechartsloc />
        </div>
        <div v-else-if="item.id === 8">
          <BarchartsSiteon />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";

// import Dropdownprovince from "@/components/Dropdownprovince.vue";
import WebService from "../../core/services/api";
import Search from "@/components/dashboard/Search.vue";
import cardstatus from "@/components/dashboard/cardstatus.vue";
import Sla from "@/components/dashboard/Sla.vue";
import Barcharts from "@/components/dashboard/Barcharts.vue";
import PiechartsPrior from "@/components/dashboard/PiechartsPrior.vue";
import Piecharts from "@/components/dashboard/Piecharts.vue";
import Piechartsloc from "@/components/dashboard/Piechartsloc.vue";
import BarchartsSiteon from "@/components/dashboard/BarchartsSiteon.vue";

export default {
  name: "dashboard",
  components: {
    Search,
    cardstatus,
    Sla,
    PiechartsPrior,
    Piecharts,
    Barcharts,
    Piechartsloc,
    BarchartsSiteon,
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Dashboard" }]);
    this.getDataUser();
  },
  data() {
    return {
      items: [],
      // profileData:JSON.parse(localStorage.getItem('profile')),
      profileData:[],
      exampleValue: 35,
    };
  },
  /*computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
  },*/
  methods: {
    getDataUser() {
      WebService.getProfilUser().then(
          (res) => {
            this.items = res.data.dashboard_items;
            this.profileData = res.data;
          },
          (error) => {
            console.log(error);
            this.items = [];
            this.profileData = {};
          }
      );
    },
    setActiveTab1(event) {
      this.tabIndex = this.setActiveTab(event);
    },
    setActiveTab2(event) {
      this.tabIndex2 = this.setActiveTab(event);
    },

    /**
     * Set current active on click
     * @param event
     */
    setActiveTab(event) {
      // get all tab links
      const tab = event.target.closest('[role="tablist"]');
      const links = tab.querySelectorAll(".nav-link");
      // remove active tab links
      for (let i = 0; i < links.length; i++) {
        links[i].classList.remove("active");
      }

      // set current active tab
      event.target.classList.add("active");

      // set clicked tab index to bootstrap tab
      return parseInt(event.target.getAttribute("data-tab"));
    },
  },
};
</script>
