<template>
  <div class="row">
    <div class="col-md-11 col-xl-12">
      <div role="alert" aria-live="polite" aria-atomic="true" class="alert alert alert-custom alert-white alert-shadow fade show gutter-b alert-light" style="max-height: 72px;border-radius: 10px;">
        <div class="d-flex align-items-md-center mb-2 flex-column flex-md-row" style="margin-top: 11px">
          <form class="
                    form
                    d-flex
                    align-items-md-center
                    flex-sm-row flex-column flex-grow-1 flex-sm-grow-0
                  ">
            <!--begin::Input-->
            <div class="d-flex align-items-center py-3 py-sm-0 px-sm-3">
                            <span class="svg-icon svg-icon-lg">
                                <!--begin::Svg Icon | path:assets/media/svg/icons/General/Search.svg-->
                                <inline-svg src="media/svg/icons/General/Search.svg" />
                              <!--end::Svg Icon-->
                            </span>
              <input type="text" class="form-control border-0 font-weight-bold pl-2"
                     placeholder="Search Here" />
            </div>
            <!--end::Input-->
            <!--begin::Input-->
            <span class="bullet bullet-ver h-25px d-none d-sm-flex mr-2"></span>
            <div class="d-flex align-items-center py-3 py-sm-0 px-sm-3">
                            <span class="svg-icon svg-icon-lg">
                                <!--begin::Svg Icon | path:assets/media/svg/icons/Media/Rec.svg-->
                                <inline-svg src="media/svg/icons/Media/Rec.svg" />
                              <!--end::Svg Icon-->
                            </span>
              <b-form-select v-model="selectedcat" :options="optionscat"
                             class="form-control border-0 font-weight-bold pl-2">
                <template #first>
                  <b-form-select-option :value="null" disabled>Select Customer</b-form-select-option>
                </template>
              </b-form-select>
            </div>
            <!--end::Input-->
            <!--begin::Input-->
            <span class="bullet bullet-ver h-25px d-none d-sm-flex mr-2"></span>
            <div class="d-flex align-items-center py-3 py-sm-0 px-sm-3">
                            <span class="svg-icon svg-icon-lg">
                                <!--begin::Svg Icon | path:assets/media/svg/icons/Media/Rec.svg-->
                                <inline-svg src="media/svg/icons/Media/Rec.svg" />
                              <!--end::Svg Icon-->
                            </span>
              <b-form-select v-model="selectedcat" :options="optionscat"
                             class="form-control border-0 font-weight-bold pl-2">
                <template #first>
                  <b-form-select-option :value="null" disabled>Select Province</b-form-select-option>
                </template>
              </b-form-select>
            </div>
            <!--end::Input-->
            <!--begin::Input-->
            <span class="bullet bullet-ver h-25px d-none d-sm-flex mr-2"></span>
            <div class="d-flex align-items-center py-3 py-sm-0 px-sm-3">
                            <span class="svg-icon svg-icon-lg">
                                <!--begin::Svg Icon | path:assets/media/svg/icons/Media/Rec.svg-->
                                <inline-svg src="media/svg/icons/Media/Rec.svg" />
                              <!--end::Svg Icon-->
                            </span>
              <b-form-select v-model="selectedcat" :options="optionscat"
                             class="form-control border-0 font-weight-bold pl-2">
                <template #first>
                  <b-form-select-option :value="null" disabled>Select City</b-form-select-option>
                </template>
              </b-form-select>
            </div>
            <!--end::Input-->
            <!--begin::Input-->
            <span class="bullet bullet-ver h-25px d-none d-sm-flex mr-2"></span>
            <div class="d-flex align-items-center py-3 py-sm-0 px-sm-3">
                            <span class="svg-icon svg-icon-lg">
                                <!--begin::Svg Icon | path:assets/media/svg/icons/General/Search.svg-->
                                <inline-svg src="media/svg/icons/General/Search.svg" />
                              <!--end::Svg Icon-->
                            </span>
              <input type="text" class="form-control border-0 font-weight-bold pl-2"
                     placeholder="Search Location" />
            </div>
            <!--end::Input-->
            <!--begin::Input-->
            <span class="bullet bullet-ver h-25px d-none d-sm-flex mr-2"></span>
            <div class="d-flex align-items-center py-3 py-sm-0 px-sm-3">
                            <span class="svg-icon svg-icon-lg">
                                <!--begin::Svg Icon | path:assets/media/svg/icons/Media/Rec.svg-->
                                <inline-svg src="media/svg/icons/Media/Rec.svg" />
                              <!--end::Svg Icon-->
                            </span>
              <b-form-select v-model="selectedcat" :options="optionscat"
                             class="form-control border-0 font-weight-bold pl-2">
                <template #first>
                  <b-form-select-option :value="null" disabled>Select Alert</b-form-select-option>
                </template>
              </b-form-select>
            </div>
            <!--end::Input-->
            <!--begin::Input-->
            <span class="bullet bullet-ver h-25px d-none d-sm-flex mr-2"></span>
            <div class="d-flex align-items-center py-3 py-sm-0 px-sm-3">
                            <span class="svg-icon svg-icon-lg">
                                <!--begin::Svg Icon | path:assets/media/svg/icons/Media/Rec.svg-->
                                <inline-svg src="media/svg/icons/Media/Rec.svg" />
                              <!--end::Svg Icon-->
                            </span>
              <b-form-select v-model="selectedcat" :options="optionscat"
                             class="form-control border-0 font-weight-bold pl-2">
                <template #first>
                  <b-form-select-option :value="null" disabled>Select Device</b-form-select-option>
                </template>
              </b-form-select>
            </div>
            <!--end::Input-->
            <!--begin::Input-->
            <span class="bullet bullet-ver h-25px d-none d-sm-flex mr-2"></span>
            <div class="d-flex align-items-center py-3 py-sm-0 px-sm-3">
                            <span class="svg-icon svg-icon-lg">
                                <!--begin::Svg Icon | path:assets/media/svg/icons/Layout/Layout-4-blocks.svg-->
                                <inline-svg src="media/svg/icons/Layout/Layout-4-blocks.svg" />
                              <!--end::Svg Icon-->
                            </span>
              <b-form-select v-model="selectedcat" :options="optionscat"
                             class="form-control border-0 font-weight-bold pl-2">
                <template #first>
                  <b-form-select-option :value="null" disabled>Select Period</b-form-select-option>
                </template>
              </b-form-select>
            </div>
            <!--end::Input-->
            <button type="submit" class="
                      btn btn-dark
                      font-weight-bold
                      btn-hover-light-primary
                      mt-3 mt-sm-0
                      px-7
                    ">
              Search
            </button>
          </form>
          <!--end::Form-->
          <!--begin::Advanced Search-->
          <div class="mt-4 my-md-0 mx-md-10">
            <a href="#" class="font-weight-bolder text-hover-primary">Clear</a>
          </div>
          <!--end::Advanced Search-->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import KTCodePreview from "@/components/CardFullWidth.vue";
export default {
  name: "dashboard",
  components: {},
  data() {
    return {
      selectedcat: null,
      optionscat: [
        { value: "true", text: "Terbaru" },
        { value: "false", text: "Terlama" },
      ],
    };
  },
};
</script>

<style>
* {
  box-sizing: border-box;
}

.subheader-background {
  background-color: #663259;
  background-position: right bottom;
  background-size: auto 100%;
  background-repeat: no-repeat;
}

.flex-container {
  display: flex;
  flex-direction: row;
  font-size: 30px;
  text-align: center;
}

.flex-item-left {
  background-color: #f1f1f1;
  padding: 5px;
  flex: 10%;
}

.flex-item-right {
  background-color: dodgerblue;
  padding: 5px;
  flex: 10%;
}

/* Responsive layout - makes a one column-layout instead of two-column layout */
@media (max-width: 1200px) {
  .flex-container {
    flex-direction: column;
  }
}

@media (max-width: 960px) {
  .flex-container {
    flex-direction: column;
  }
}

@media (max-width: 800px) {
  .flex-container {
    flex-direction: column;
  }
}

@media (max-width: 400px) {
  .flex-container {
    flex-direction: column;
  }
}
</style>
