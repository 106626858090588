<template>
  <div class="container-fluid">
    <vueper-slides
      class="no-shadow"
      :visible-slides="7"
      fixed-height="100px"
      slide-multiple
      :bullets="false"
      :gap="2"
      :slide-ratio="1 / 18"
      :dragging-distance="200"
      :breakpoints="{
        800: { visibleSlides: 2, slideMultiple: 2, slideRatio: 1 / 6, gap: 2 },
        1000: { visibleSlides: 3, slideMultiple: 3, slideRatio: 1 / 8, gap: 2 },
        1200: { visibleSlides: 4, slideMultiple: 4, slideRatio: 1 / 8, gap: 2 },
        1400: {
          visibleSlides: 5,
          slideMultiple: 5,
          slideRatio: 1 / 10,
          gap: 2,
        },
        1700: {
          visibleSlides: 6,
          slideMultiple: 6,
          slideRatio: 1 / 12,
          gap: 2,
        },
      }"
    >
      <vueper-slide
        v-for="(status, i) in cardStatus"
        :key="i"
        :title="status.title"
      >
        <template #content>
          <div
            class="card text-center"
            style="
              cursor: pointer;
              height: 94px;
              background: var(--card-bg);
              box-shadow: 0 1px 2px rgba(0, 0, 0, 0.12);
              border-radius: 8px;
              border-color: var(--bordercolorform);
            "
          >
            <div class="mt-5" @click="detailCard(status.type)">
              <p
                class="card-status-title"
                style="color: var(--textlabelkuning)"
              >
                {{ status.title }}
              </p>
              <p class="card-status-text" style="color: #74f972">
                {{ status.value }}
              </p>
            </div>

            <b-modal
              v-model="detail"
              size="lg"
              title="Detail Status"
              centered
              @hidden="onHidden"
            >
              <div style="margin: 2%">
                <DxDataGrid
                  :ref="my - data - grid"
                  :data-source="itemsdetail"
                  :show-borders="true"
                  :word-wrap-enabled="false"
                  :column-auto-width="true"
                  :remote-operations="true"
                  :column-hiding-enabled="false"
                >
                  <DxSearchPanel :visible="true" />
                  <DxFilterRow :visible="false" />
                  <DxExport :enabled="true" />
                  <DxColumn data-field="serial" caption="Serial" :width="100" />
                  <DxColumn data-field="name" caption="Name" :width="150" />
                  <DxColumn
                    data-field="battery_level"
                    caption="Battery"
                    :width="150"
                  />
                  <DxColumn
                    data-field="location"
                    caption="Location"
                    :width="170"
                  />
                  <DxPaging :page-size="10" />
                </DxDataGrid>
              </div>
            </b-modal>
          </div>
        </template>
      </vueper-slide>
    </vueper-slides>
  </div>
</template>

<script>
import { VueperSlides, VueperSlide } from "vueperslides";
import "vueperslides/dist/vueperslides.css";
import WebService from "../../core/services/api";
import {
  DxDataGrid,
  DxExport,
  DxFilterRow,
  DxPaging,
  DxSearchPanel,
} from "devextreme-vue/data-grid";

export default {
  components: {
    VueperSlides,
    VueperSlide,
    DxDataGrid,
    DxSearchPanel,
    DxFilterRow,
    DxExport,
    DxPaging,
  },
  data() {
    return {
      detail: false,
      cardStatus: {},
      jumlah_pending: "",
      pendingDetail: [],
      itemsdetail: [
        {
          no: "1",
          location: "BCA 355U Alfamart Q215 Teuku Umar Barat",
          alert: "Power Up",
          time: "03/12/2021, 09:08:50",
        },
        {
          no: "2",
          location: "BCA 355U Alfamart Q215 Teuku Umar Barat",
          alert: "Power Up",
          time: "03/12/2021, 09:08:50",
        },
        {
          no: "3",
          location: "BCA 355U Alfamart Q215 Teuku Umar Barat",
          alert: "Power Up",
          time: "03/12/2021, 09:08:50",
        },
        {
          no: "4",
          location: "BCA 355U Alfamart Q215 Teuku Umar Barat",
          alert: "Power Up",
          time: "03/12/2021, 09:08:50",
        },
      ],
      fieldsdetail: [
        {
          key: "no",
          label: "No",
          class: "text-center",
        },
        {
          key: "location",
          label: "Location",
          sortable: false,
          class: "text-center",
        },
        {
          key: "alert",
          label: "Alert",
          sortable: false,
          class: "text-center",
        },
        {
          key: "time",
          label: "Time",
          sortable: false,
          class: "text-center",
        },
        {
          formatter: (value) => {
            return value ? "Yes" : "No";
          },

          sortable: true,
          sortByFormatted: true,
          filterByFormatted: true,
        },
      ],
    };
  },

  computed: {},
  methods: {
    onHidden() {
      this.getAllDataDevice();
    },
    detailCard(type) {
      if (type == "bt15") {
        const range = {
          min: 0,
          max: 15,
        };
        const arrdata = this.itemsdetail;
        this.itemsdetail = arrdata.filter(function (o) {
          return o.battery_level <= range.max && o.battery_level >= range.min;
        });
        this.detail = !this.detail;
      } else if (type == "bt30") {
        const range = {
          min: 0,
          max: 30,
        };
        const arrdata = this.itemsdetail;
        this.itemsdetail = arrdata.filter(function (o) {
          return o.battery_level <= range.max && o.battery_level >= range.min;
        });
        this.detail = !this.detail;
      } else if (type == "bt50") {
        const range = {
          min: 0,
          max: 50,
        };
        const arrdata = this.itemsdetail;
        this.itemsdetail = arrdata.filter(function (o) {
          return o.battery_level <= range.max && o.battery_level >= range.min;
        });
        this.detail = !this.detail;
      } else if (type == "btabv50") {
        const range = {
          min: 50,
          max: 100,
        };
        const arrdata = this.itemsdetail;
        this.itemsdetail = arrdata.filter(function (o) {
          return o.battery_level <= range.max && o.battery_level >= range.min;
        });
        this.detail = !this.detail;
      } else if (type == "pending") {
        // this.itemsdetail = this.pendingDetail;
        this.$router.push("/pendingpart");
      } else if (type == "stock") {
        // this.itemsdetail = this.pendingDetail;
        this.$router.push("/logistik");
      } else {
        this.itemsdetail = [];
      }
    },
    getDataUser() {
      WebService.getProfilUser().then(
        (res) => {
          this.profileData = res.data;
          if (res.data.role_id !== 31) {
            this.getAllDataDevice();
            this.getStatus();
          } else {
            console.log("test data user");
            const custid = res.data.customer.name;
            this.getAlldataOnlineDeviceByOwner(custid);
            this.getStatus();
          }
        },
        (error) => {
          console.log(error);
          this.profileData = {};
        }
      );
    },
    getAlldataOnlineDeviceByOwner(cust) {
      WebService.getAlldataOnlineDeviceByOwner(cust).then(
        (res) => {
          this.itemsdetail = res.data;
        },
        (error) => {
          console.log(error, "error");
          this.itemsdetail = [];
        }
      );
      this.loading = false;
    },
    getAllDataDevice() {
      WebService.getAlldataOnlineDevice().then(
        (res) => {
          this.itemsdetail = res.data;
        },
        (error) => {
          console.log(error, "error");
          this.itemsdetail = [];
        }
      );
      this.loading = false;
    },
    getStatus() {
      WebService.getDashboardStatus().then((response) => {
        let body = {
          endDate: "2022-05-31",
          startDate: "2022-05-01",
        };
        WebService.getPendingPart(body).then((res) => {
          this.pendingDetail = res.data;
          // this.cardStatus[7].value = 99;
          //  this.jumlah_pending = res.data.length;
          this.jumlah_pending = 0;
          WebService.getAlllogistikDashboard().then((resdata) => {
            this.cardStatus = [
              {
                title: "Normal",
                type: "-",
                value: response.data.data.online,
              },
              {
                title: "Anomaly",
                type: "-",
                value: response.data.data.anomaly,
              },
              {
                title: "Offline",
                type: "-",
                value: response.data.data.offline,
              },
              {
                title: "Trouble",
                type: "-",
                value: "0",
              },
              {
                title: "Device Total",
                type: "-",
                value: response.data.data.deviceTotal,
              },
              {
                title: "Intermittent",
                type: "-",
                value: "0",
              },
              {
                title: "Stock",
                type: "stock",
                value: resdata.data.data.length,
              },
              {
                title: "Pending Part",
                type: "pending",
                value: 0,
              },
              {
                title: "Battery Below 15%",
                type: "bt15",
                value: response.data.data.battery15,
              },
              {
                title: "Battery Below 30%",
                type: "bt30",
                value: response.data.data.battery30,
              },
              {
                title: "Battery Below 50%",
                type: "bt50",
                value: response.data.data.battery50,
              },
              {
                title: "Battery Abv 50%",
                type: "btabv50",
                value: response.data.data.batteryAbv50,
              },
            ];
          });
        });
      });
    },
  },
  mounted() {
    this.getDataUser();
  },
};
</script>

<style scoped>
.card-status-title {
  font-size: 14px;
  font-weight: normal;
  color: #757575;
}
.card-status-text {
  font-size: 25px;
  color: #360302;
  font-weight: 600;
}
</style>
