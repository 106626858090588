<template>
  <div class="card card-custom mb-3">
    <div class="card-header">
      <div class="card-title">
        <div class="mt-2 mt-2 ml-2">
          <h3 class="card-label cardLabelTiket">Problem</h3>
        </div>
        <div class="col-6 mb-2 mt-2">
          <b-form-select
            v-model="selected"
            :options="options"
            @change="bankChange()"
            class="mb-3"
            value-field="item"
            text-field="name"
            disabled-field="notEnabled"
            style="background-color: var(--formcolor); border-color: var(--bordercolorform); color: var(--txtlabeloption);"
          ></b-form-select>
        </div>
      </div>
      <div class="card-toolbar">
        <div
          class="dropdown dropdown-inline"
          data-toggle="tooltip"
          title=""
          data-placement="left"
          data-original-title="Quick actions"
        >
          <a
            href="#"
            @click="getDetailData()"
            class="btn btn-hover-light-primary btn-sm btn-icon"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <i class="ki ki-bold-more-hor"></i>
          </a>
        </div>
      </div>
    </div>

    <div class="row no-gutters" style="justify-content: center !important">
      <div style="width: 100%;min-height: 300px">
        <DoughnutExample
          :width="250"
          :height="250"
          ref="piechart"
          :chart-data="chartData"
          :options="options2"
        >
        </DoughnutExample>
      </div>
    </div>

    <b-modal v-model="detail" size="lg" title="Detail" centered hide-footer>
      <div style="max-width: 100%">
        <DxDataGrid
          :ref="my - data - grid"
          :data-source="detailArray"
          :show-borders="true"
          :remote-operations="true"
        >
          <DxSearchPanel :visible="true" />
          <DxFilterRow :visible="false" />
          <DxExport :enabled="true" />
          <DxColumn data-field="site_id" caption="Site ID" />
          <DxColumn data-field="problem" caption="Problem" />
          <DxColumn data-field="site_location" caption="Site Location" />
          <DxPaging :page-size="10" />
        </DxDataGrid>
      </div>
    </b-modal>
  </div>
</template>

<script>
import DoughnutExample from "../dashboard/chart/demo";
import WebService from "../../core/services/api";
import {
  DxDataGrid,
  DxSearchPanel,
  DxFilterRow,
  DxColumn,
  DxExport,
  DxPaging
} from "devextreme-vue/data-grid";
import randomColor from "randomcolor";

const options2 = {
  legend: {
    display: false
  },
  responsive: true,
  maintainAspectRatio: false,
  animation: {
    animateRotate: false
  }
};
export default {
  components: {
    DoughnutExample,
    DxDataGrid,
    DxSearchPanel,
    DxFilterRow,
    DxColumn,
    DxExport,
    DxPaging
  },
  data() {
    return {
      detail: false,
      detailArray: [],
      profileData: {},
      options2,
      chartData: {
        labels: [],
        datasets: [
          {
            backgroundColor: [
              "#F97372",
              "#F6F972",
              "#9DF972",
              "#7277F9",
              "#F9C372"
            ],
            data: []
          }
        ]
      },
      chartOptions: {
        colors: ["#F97372", "#F6F972", "#9DF972", "#7277F9", "#F9C372"],
        stroke: {
          width: 0
        },
        legend: {
          fontSize: "14px",
          fontFamily: "Helvetica, Arial",
          labels: {
            colors: "var(--textlabelkuning)",
            useSeriesColors: false
          },
          fontWeight: 400,
          itemMargin: {
            vertical: 10
          }
          // formatter: function(seriesName) {
          //   return '<label style="color: var(--textlabelkuning)">' + '<span>' + seriesName + '</span>' + '</label>'
          // }
        },
        dataLabels: {
          enabled: false
        },
        labels: [],
        responsive: [
          {
            breakpoint: 700,
            options: {
              chart: {
                height: 300,
                width: 350
              },
              legend: {
                position: "bottom",
                fontSize: "11px"
              }
            }
          },
          {
            breakpoint: 768,
            options: {
              chart: {
                height: 300,
                width: 380
              },
              legend: {
                position: "bottom",
                fontSize: "11px"
              }
            }
          },
          {
            breakpoint: 1500,
            options: {
              chart: {
                height: 300,
                width: 400
              },
              legend: {
                position: "bottom",
                fontSize: "11px"
              }
            }
          }
        ],
        plotOptions: {
          pie: {
            donut: {
              labels: {
                show: true,
                name: {
                  show: true,
                  fontSize: "22px",
                  fontFamily: "Rubik",
                  color: "var(--textlabelkuning)",
                  offsetY: -10
                },
                value: {
                  show: true,
                  fontSize: "16px",
                  fontFamily: "Helvetica, Arial, sans-serif",
                  color: "var(--textlabelkuning)",
                  offsetY: 16,
                  formatter: function(val) {
                    return val;
                  }
                },
                total: {
                  show: true,
                  fontSize: "12px",
                  label: "Total",
                  color: "var(--textlabelkuning)",
                  formatter: function(w) {
                    return w.globals.seriesTotals.reduce((a, b) => {
                      return a + b;
                    }, 0);
                  }
                }
              }
            }
          }
        },
        series: []
      },
      selected: "All",
      options: []
    };
  },
  mounted() {
    this.getDataUser();
  },
  methods: {
    getDataUser() {
      WebService.getProfilUser().then(
        res => {
          this.profileData = res.data;
          if (res.data.role_id !== 31) {
            this.getChart();
            this.getDetail();
          } else {
            const custid = res.data.customer.name;
            this.getChartOwner(custid);
            this.getDetail();
          }
        },
        error => {
          console.log(error);
          this.profileData = {};
        }
      );
    },
    getDetailData() {
      this.detail = true;
      if (this.selected === "All") {
        WebService.getAllTiketPending().then(
          res => {
            this.detailArray = res.data.data;
          },
          error => {
            console.log(error);
            this.detailArray = [];
          }
        );
      } else {
        WebService.getAllPendingByProblem(this.selected).then(
          res => {
            this.detailArray = res.data.data;
          },
          error => {
            console.log(error);
            this.detailArray = [];
          }
        );
      }
    },
    bankChange() {
      const bankid = this.selected;
      const customer = this.profileData.customer.name;
      if (bankid === "All") {
        this.getChart();
      } else {
        if (this.profileData.role_id !== 31) {
          WebService.getProblem(bankid).then(
            res => {
              const arrVal = [];
              const bgArray = [];
              res.data.data.forEach(item => {
                this.chartOptions.labels.push(item.group_name);
                this.chartData.labels.push(item.group_name);
                arrVal.push(Number(item.value));
                bgArray.push(randomColor());
              });
              this.chartOptions.series = arrVal;
              this.chartData.datasets[0].data = arrVal;
              this.chartData.datasets[0].backgroundColor = bgArray;
              console.log(arrVal);
              this.$refs.piechart.update();
            },
            error => {
              console.log(error);
              this.pendingData = 0;
              this.newData = 0;
            }
          );
        } else {
          WebService.getProblemByOwner(bankid, customer).then(
            res => {
              const arrVal = [];
              const bgArray = [];
              res.data.data.forEach(item => {
                this.chartOptions.labels.push(item.group_name);
                this.chartData.labels.push(item.group_name);
                arrVal.push(Number(item.value));
                bgArray.push(randomColor());
              });
              this.chartOptions.series = arrVal;
              this.chartData.datasets[0].data = arrVal;
              this.chartData.datasets[0].backgroundColor = bgArray;
              this.$refs.piechart.update();
            },
            error => {
              console.log(error);
              this.pendingData = 0;
              this.newData = 0;
            }
          );
        }
      }
    },
    getDetail() {
      const customer = this.profileData.customer.name;
      if (this.profileData.role_id !== 31) {
        WebService.getAllTiketPending().then(
          res => {
            this.detailArray = res.data.data;
          },
          error => {
            console.log(error);
            this.detailArray = [];
          }
        );
      } else {
        WebService.getAllTiketPendingByCustomer(customer).then(
          res => {
            this.detailArray = res.data.data;
          },
          error => {
            console.log(error);
            this.detailArray = [];
          }
        );
      }
      this.loading = false;
    },
    getChart() {
      WebService.getchartBygroupField("problem").then(
        res => {
          const arrVal = [];
          const bgArray = [];
          res.data.data.forEach(item => {
            this.chartOptions.labels.push(item.group_name);
            this.chartData.labels.push(item.group_name);
            arrVal.push(Number(item.value));
            bgArray.push(randomColor());
          });
          this.chartOptions.series = arrVal;
          this.chartData.datasets[0].data = arrVal;
          this.chartData.datasets[0].backgroundColor = bgArray;
          this.$refs.piechart.update();

          const roles = res.data.data;
          let selectedOptions;
          selectedOptions = [];
          const arrAll = {
            name: "All",
            item: "All"
          };
          roles.map(i => {
            return selectedOptions.push({
              name: i.group_name,
              item: i.group_name
            });
          });
          const newArray = [arrAll].concat(selectedOptions);
          this.options = newArray;
        },
        error => {
          console.log(error);
          this.pendingData = 0;
          this.newData = 0;
        }
      );
      this.loading = false;
    },
    getChartOwner(cust) {
      WebService.getProblemChartByOwner(cust).then(
        res => {
          const arrVal = [];
          const bgArray = [];
          res.data.data.forEach(item => {
            this.chartOptions.labels.push(item.group_name);
            this.chartData.labels.push(item.group_name);
            arrVal.push(Number(item.value));
            bgArray.push(randomColor());
          });
          this.chartOptions.series = arrVal;
          this.chartData.datasets[0].data = arrVal;
          this.chartData.datasets[0].backgroundColor = bgArray;
          this.$refs.piechart.update();

          const roles = res.data.data;
          let selectedOptions;
          selectedOptions = [];
          const arrAll = {
            name: "All",
            item: "All"
          };
          roles.map(i => {
            return selectedOptions.push({
              name: i.group_name,
              item: i.group_name
            });
          });
          const newArray = [arrAll].concat(selectedOptions);
          this.options = newArray;
        },
        error => {
          console.log(error);
          this.pendingData = 0;
          this.newData = 0;
        }
      );
      this.loading = false;
    }
  }
};
</script>

<style></style>
