<template>
  <div class="card card-custom mb-3">
    <div class="card-header">
      <div class="card-title">
        <h3 class="card-label cardLabelTiket">SLA</h3>
      </div>
      <div class="card-toolbar">
        <div
          class="dropdown dropdown-inline"
          data-toggle="tooltip"
          title=""
          data-placement="left"
          data-original-title="Quick actions"
        >
          <a
            href="#"
            class="btn btn-hover-light-primary btn-sm btn-icon"
            data-toggle="dropdown"
            aria-haspopup="true"
            @click="detail = !detail"
            aria-expanded="false"
          >
            <i class="ki ki-bold-more-hor"></i>
          </a>
        </div>
      </div>
    </div>

    <div class="row no-gutters" style="justify-content: center !important">
      <div style="width: 100%">
        <div id="chart1" style="min-height: 300px">
          <apexchart :options="options1" :series="series"></apexchart>
        </div>
      </div>

      <b-modal v-model="detail" size="lg" title="Detail SLA" centered hide-footer>
        <div>
          <div style="margin: 2%">
            <DxDataGrid
              :ref="my - data - grid"
              :data-source="itemsdetailsla"
              :show-borders="true"
              :word-wrap-enabled="false"
              :column-auto-width="false"
              :remote-operations="true"
              :column-hiding-enabled="true"
            >
              <DxSearchPanel :visible="true" />
              <DxFilterRow :visible="false" />
              <DxExport :enabled="true" />
              <DxColumn data-field="site_id" caption="Site ID" :width="100" />
              <DxColumn data-field="problem" caption="Problem" :width="150" />
              <DxColumn
                data-field="description"
                caption="Problem Solved"
                :width="150"
              />
              <DxColumn
                data-field="site_location"
                caption="Site Location"
                :width="170"
              />
              <DxColumn data-field="status" caption="Status" :width="70" />
              <DxPaging :page-size="10" />
            </DxDataGrid>
          </div>
        </div>
        <!-- <pre>{{ infoModal.content }}</pre> -->
      </b-modal>
    </div>
  </div>
</template>

<script>
// import Gauge from "./components/Gauge.vue";
import WebService from "../../core/services/api";
import {
  DxDataGrid,
  DxSearchPanel,
  DxFilterRow,
  DxColumn,
  DxExport,
  DxPaging
} from "devextreme-vue/data-grid";
export default {
  name: "App",
  components: {
    // Gauge,
    DxDataGrid,
    DxSearchPanel,
    DxFilterRow,
    DxColumn,
    DxExport,
    DxPaging
  },
  data() {
    return {
      detail: false,
      exampleValue: 0,
      itemsdetailsla: [],
      series: [0],
      options1: {
        chart: {
          height: 280,
          type: "radialBar"
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200,
                offsetX: 5
              }
            }
          },
          {
            breakpoint: 768,
            options: {
              chart: {
                height: 300,
                offsetX: -55,
                width: 500
              }
            }
          },

          {
            breakpoint: 1200,
            options: {
              chart: {
                height: 280,
                offsetX: -45,
                offsetY: 0,
                width: 350
              }
            }
          },

          {
            breakpoint: 1400,
            options: {
              chart: {
                height: 285,
                offsetX: -70,
                offsetY: 0,
                width: 350
              }
            }
          },

          {
            breakpoint: 1980,
            options: {
              chart: {
                height: 270,
                offsetX: -40,
                offsetY: 0,
                width: 350
              }
            }
          }
        ],

        colors: ["#f97272"],
        plotOptions: {
          radialBar: {
            inverseOrder: false,
            startAngle: -90,
            endAngle: 90,
            offsetX: 0,
            offsetY: 25,
            hollow: {
              margin: 5,
              size: "60%",
              background: "transparent",
              image: undefined,
              imageWidth: 150,
              imageHeight: 750,
              imageOffsetX: 0,
              imageOffsetY: 0,
              imageClipped: true,
              position: "front",
              dropShadow: {
                enabled: false,
                top: 0,
                left: 0,
                blur: 3,
                opacity: 0.5
              }
            },
            track: {
              show: true,
              startAngle: undefined,
              endAngle: undefined,
              background: "#f2f2f2",
              strokeWidth: "70%",
              opacity: 1,
              margin: 7,
              dropShadow: {
                enabled: false,
                top: 0,
                left: 0,
                blur: 3,
                opacity: 0.5
              }
            },
            dataLabels: {
              show: true,
              name: {
                show: true,
                fontSize: "16px",
                fontFamily: undefined,
                fontWeight: 600,
                color: undefined,
                offsetY: -10
              },
              value: {
                show: true,
                fontSize: "20px",
                fontFamily: undefined,
                fontWeight: 400,
                color: "var(--textlabelkuning)",
                offsetY: 16,
                formatter: function(val) {
                  return val + "%";
                }
              },
              total: {
                show: false,
                label: "Total",
                color: "#373d3f",
                fontSize: "16px",
                fontFamily: undefined,
                fontWeight: 600,
                formatter: function(w) {
                  return (
                    w.globals.seriesTotals.reduce((a, b) => {
                      return a + b;
                    }, 0) /
                      w.globals.series.length +
                    "%"
                  );
                }
              }
            }
          }
        },
        fill: {
          type: "gradient",
          gradient: {
            shade: "green",
            type: "horizontal",
            gradientToColors: ["#9df972"],
            stops: [0, 100]
          }
        },
        stroke: {
          lineCap: "butt"
        },
        labels: ["Availability"]
      }
    };
  },
  mounted() {
    this.getDataUser();
  },
  methods: {
    getDataUser() {
      WebService.getProfilUser().then(
        res => {
          this.profileData = res.data;
          if (res.data.role_id !== 31) {
            this.getChartDetail();
            this.getChart();
          } else {
            console.log("test data user");
            const custid = res.data.customer.name;
            this.getChartDetailBycustomer(custid);
            this.getChartBycustomer(custid);
          }
        },
        error => {
          console.log(error);
          this.profileData = {};
        }
      );
    },
    getChart() {
      WebService.getchartBygroupField("status").then(
        res => {
          var keyword = ["new", "pending"];
          var filtered = res.data.data.filter(function(el) {
            return keyword.indexOf(el.group_name) >= 0;
          });
          var angka1 = filtered[0].value;
          var angka2 = filtered[1].value;
          var arrHasil = [];
          var slaHasil = arrHasil.push((angka2 / angka1) * 100);
          this.series = [slaHasil];
        },
        error => {
          console.log(error);
          this.series = [0];
        }
      );
      this.loading = false;
    },
    getChartBycustomer(cust) {
      WebService.getchartBygroupFieldByCustomer(cust).then(
        res => {
          var keyword = ["new", "pending"];
          var filtered = res.data.data.filter(function(el) {
            return keyword.indexOf(el.group_name) >= 0;
          });
          var angka1 = filtered[0].value;
          var angka2 = filtered[1].value;
          var arrHasil = [];
          var slaHasil = arrHasil.push((angka2 / angka1) * 100);
          this.series = [slaHasil];
        },
        error => {
          console.log(error);
          this.series = [0];
        }
      );
      this.loading = false;
    },
    getChartDetail() {
      WebService.getDetailSla().then(
        res => {
          this.itemsdetailsla = res.data.data;
        },
        error => {
          console.log(error, "error");
          this.itemsdetailsla = [];
        }
      );
      this.loading = false;
    },
    getChartDetailBycustomer(cust) {
      WebService.getDetailSlaBycustomer(cust).then(
        res => {
          this.itemsdetailsla = res.data.data;
        },
        error => {
          console.log(error, "error");
          this.itemsdetailsla = [];
        }
      );
      this.loading = false;
    }
  }
};
</script>

<style>
#app {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(430px, 1fr));
  grid-gap: 4em;
  padding: 2em;
  place-items: center center;
}

#chart1 {
  float: start;
  margin-left: auto;
  margin-right: auto;
  width: 60%;
}
</style>
