<template>
  <div class="card card-custom mb-3">
    <div class="card-header">
      <div class="card-title">
        <div class="mt-2 mt-2 ml-2">
          <h3 class="card-label cardLabelTiket">Client Status</h3>
        </div>
        <div class="col-6 mb-2 mt-2" v-if="profileData.role_id !== 31">
          <b-form-select
            v-model="selected"
            :options="options"
            @change="bankChange()"
            class="mb-3"
            value-field="item"
            text-field="name"
            disabled-field="notEnabled"
            style="background-color: var(--formcolor); border-color: var(--bordercolorform); color: var(--txtlabeloption);"
          ></b-form-select>
        </div>
      </div>
      <div class="card-toolbar">
        <div
          class="dropdown dropdown-inline"
          data-toggle="tooltip"
          title=""
          data-placement="left"
          data-original-title="Quick actions"
        >
          <a
            href="#"
            @click="getDetailData()"
            class="btn btn-hover-light-primary btn-sm btn-icon"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <i class="ki ki-bold-more-hor"></i>
          </a>
        </div>
      </div>
    </div>

    <div class="row no-gutters" style="justify-content: center !important;">
      <div style="width: 100% ; height:60%;">
        <div class="card-body">
          <div id="chart">
            <apexchart
              type="bar"
              height="165%"
              :options="chartOptions"
              :series="series"
            ></apexchart>
          </div>
        </div>
      </div>

      <b-modal v-model="detail" size="lg" title="Detail" centered hide-footer>
        <DxDataGrid
          :ref="my - data - grid"
          :data-source="detailArray"
          :show-borders="true"
          :remote-operations="true"
        >
          <DxSearchPanel :visible="true" />
          <DxFilterRow :visible="false" />
          <DxExport :enabled="true" />
          <DxColumn data-field="site_id" caption="SITE ID" />
          <DxColumn data-field="location_name" caption="Location Name" />
          <DxColumn data-field="customer.name" caption="Customer Name" />
          <DxColumn data-field="site_class_name" caption="Site Class" />
          <DxPaging :page-size="10" />
        </DxDataGrid>
      </b-modal>
    </div>
  </div>
</template>

<script>
import VueApexCharts from "vue-apexcharts";
import WebService from "../../core/services/api";
import {
  DxDataGrid,
  DxSearchPanel,
  DxFilterRow,
  DxColumn,
  DxExport,
  DxPaging
} from "devextreme-vue/data-grid";
export default {
  name: "Barchart",
  components: {
    apexchart: VueApexCharts,
    DxDataGrid,
    DxSearchPanel,
    DxFilterRow,
    DxColumn,
    DxExport,
    DxPaging
  },
  data() {
    return {
      detail: false,
      detailArray: [],
      profileData: [],
      itemsdetail: [],
      series: [],
      chartOptions: {
        chart: {
          type: "bar",
          height: 350
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: "55%",
            endingShape: "rounded"
          }
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          show: true,
          width: 2,
          colors: ["transparent"]
        },

        xaxis: {
          categories: ["Location"]
        },
        yaxis: {
          title: {
            text: "Jumlah"
          }
        },
        fill: {
          opacity: 1
        },
        tooltip: {
          y: {
            formatter: function(val) {
              return val;
            }
          }
        }
      },

      selected: "All",
      options: []
    };
  },
  mounted() {
    this.getAllCustomer();
    this.getDataUser();
  },
  methods: {
    getDataUser() {
      WebService.getProfilUser().then(
        res => {
          this.profileData = res.data;
          if (res.data.role_id !== 31) {
            this.getChart();
          } else {
            const custid = res.data.customer_id;
            this.getDatabyBankId(custid);
          }
        },
        error => {
          console.log(error);
          this.profileData = {};
        }
      );
    },
    getDetailData() {
      this.detail = true;
      if (this.selected === "All") {
        WebService.getAllTiketPending().then(
          res => {
            this.detailArray = res.data.data;
          },
          error => {
            console.log(error);
            this.detailArray = [];
          }
        );
      } else {
       WebService.getlocalsiteById(this.selected).then(
          res => {
            this.detailArray = res.data.data;
          },
          error => {
            console.log(error);
            this.detailArray = [];
          }
        );
      }
    },
    getDatabyBankId(bankid) {
      WebService.getlocalsiteById(bankid).then(
        res => {
          console.log(res.data.data);
          this.detailArray = res.data.data;
          var counts = res.data.data.reduce((p, c) => {
            var name = c.location_name;
            if (!p.hasOwnProperty(name)) {
              p[name] = 0;
            }
            p[name]++;
            return p;
          }, {});

          var countsExtended = Object.keys(counts).map(k => {
            return { name: k, data: [counts[k]] };
          });
          // const arrVal = [];
          // res.data.data.forEach((item) => {
          //   this.chartOptions.labels.push(item.group_name);
          //   arrVal.push(item.value);
          // })
          this.series = countsExtended.slice(
            Math.max(countsExtended.length - 20, 0)
          );
          // console.log(this.chartOptions);
        },
        error => {
          console.log(error);
          this.detailArray = [];
          // this.pendingData = 0;
          // this.newData = 0;
        }
      );
    },
    bankChange() {
      const bankid = this.selected;
      WebService.getlocalsiteById(bankid).then(
        res => {
          console.log(res.data.data);
          this.detailArray = res.data.data;
          var counts = res.data.data.reduce((p, c) => {
            var name = c.location_name;
            if (!p.hasOwnProperty(name)) {
              p[name] = 0;
            }
            p[name]++;
            return p;
          }, {});

          var countsExtended = Object.keys(counts).map(k => {
            return { name: k, data: [counts[k]] };
          });
          // const arrVal = [];
          // res.data.data.forEach((item) => {
          //   this.chartOptions.labels.push(item.group_name);
          //   arrVal.push(item.value);
          // })
          this.series = countsExtended.slice(
            Math.max(countsExtended.length - 20, 0)
          );
          // console.log(this.chartOptions);
        },
        error => {
          console.log(error);
          this.detailArray = [];
          // this.pendingData = 0;
          // this.newData = 0;
        }
      );
    },
    getAllCustomer() {
      WebService.getRefCustomer().then(async res => {
        const roles = res.data.data;
        let selectedOptions;
        selectedOptions = [];
        const arrAll = {
          name: "All",
          item: "All"
        };
        roles.map(i => {
          return selectedOptions.push({
            name: i.name,
            item: i.id
          });
        });
        const newArray = [arrAll].concat(selectedOptions);
        this.options = newArray;
      });
    },
    getChart() {
      let databody = JSON.stringify([{ selector: "created_at", desc: true }]);
      WebService.getlocalsiteAll(encodeURI(databody)).then(
        res => {
          console.log(res.data.data);
          this.detailArray = res.data.data;
          var counts = res.data.data.reduce((p, c) => {
            var name = c.location_name;
            if (!p.hasOwnProperty(name)) {
              p[name] = 0;
            }
            p[name]++;
            return p;
          }, {});

          var countsExtended = Object.keys(counts).map(k => {
            return { name: k, data: [counts[k]] };
          });
          // const arrVal = [];
          // res.data.data.forEach((item) => {
          //   this.chartOptions.labels.push(item.group_name);
          //   arrVal.push(item.value);
          // })
          this.series = countsExtended.slice(
            Math.max(countsExtended.length - 20, 0)
          );
          // console.log(this.chartOptions);
        },
        error => {
          console.log(error);
          this.detailArray = [];
          // this.pendingData = 0;
          // this.newData = 0;
        }
      );
      this.loading = false;
    }
  }
};
</script>

<style></style>
